import {
  Anchor,
  AppShell,
  Box,
  Container,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  rem,
} from "@mantine/core";
import { useTranslation } from "next-i18next";
import { IconClock, IconMail, IconPhone } from "@tabler/icons-react";
import Image from "next/image";
import Link from "next/link";
import { getAssetUrl } from "@/utils";

export function Footer(): JSX.Element {
  const { t, i18n } = useTranslation();

  const FooterLink = (data: {
    link: string;
    title: string;
    target?: string;
    noLocale?: boolean;
  }) => {
    const { link, title, target, noLocale } = data;

    return (
      <Anchor
        locale={!noLocale && i18n.language}
        className="hover:underline underline-offset-4 decoration-1"
        component={Link}
        c="white"
        size="sm"
        href={link}
        target={target}
      >
        {title}
      </Anchor>
    );
  };

  return (
    <AppShell.Footer pos="relative" zIndex={99}>
      <Box bg="blue.9">
        <Container size={rem(1200)} py={rem(40)}>
          <SimpleGrid
            cols={{ base: 1, md: 4 }}
            verticalSpacing="xl"
            className="flex-none md:flex-1"
          >
            <Stack>
              <Image
                src={getAssetUrl("/japan-bullet-train.svg")}
                alt={t("meta:home.title")}
                width={250}
                height={80}
              />
              <Image
                src={getAssetUrl("/japan-travel.webp")}
                alt="Japan. Endless Discovery."
                width={210}
                height={47}
                className="ml-5 hidden md:block"
              />
            </Stack>
            <Stack gap={rem(10)}>
              <Text className="uppercase text-sm !text-blue-3 font-bold">
                {t("common:links.service")}
              </Text>
              <FooterLink link="/" title={t("common:links.home")} />
              <FooterLink
                link={`/guide/${t("common:guide.1.url")}`}
                title={t("common:guide.1.title")}
              />
              <FooterLink link="/faq" title={t("common:links.faq")} />
              <FooterLink
                link="/affiliate"
                title={t("common:links.affiliate")}
              />
            </Stack>
            <Stack gap={rem(10)}>
              <Text className="uppercase text-sm !text-blue-3 font-bold">
                {t("common:links.legal")}
              </Text>
              <FooterLink
                link={
                  ["ko", "zh-tw", "zh-cn"].includes(i18n.language)
                    ? "/assets/stipulation/terms-cn-tw-kr.pdf"
                    : "/assets/stipulation/terms-en.pdf"
                }
                title={t("common:links.terms")}
                noLocale
              />
              <FooterLink
                link="https://www.inbound-platform.com/wp-content/uploads/2024/01/a98d8d8d0ff1558f58e11849889dcf3b.pdf"
                title={t("common:links.travel_agency_agreement")}
                target="_blank"
              />
              <FooterLink
                link="https://www.inbound-platform.com/en/privacy/"
                title={t("common:links.privacy")}
                target="_blank"
              />
              <FooterLink
                link="https://www.inbound-platform.com/en/"
                title={t("common:links.company")}
                target="_blank"
              />
            </Stack>
            <Stack gap={rem(10)}>
              <Text className="uppercase text-sm !text-blue-3 font-bold">
                {t("common:links.cs")}
              </Text>
              <Anchor
                locale={i18n.language}
                component={Link}
                className={`flex items-center gap-2 hover:underline underline-offset-4
                            decoration-1 !text-white text-sm`}
                href="/contact"
              >
                <IconMail strokeWidth="1" size={20} />
                {t("common:links.contact")}
              </Anchor>
              <Anchor
                locale={i18n.language}
                component={Link}
                className={`flex items-center gap-2 hover:underline underline-offset-4
                            decoration-1 !text-white text-sm`}
                href={`tel:${t("common:service.contact")}`}
              >
                <IconPhone strokeWidth="1" size={20} />
                {t("common:service.contact")}
              </Anchor>
              <Text c="white" size="sm" className="flex items-center gap-2">
                <IconClock strokeWidth="1" size={20} />
                {t("common:service.business_hours")}
              </Text>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
      <Box bg="blue.8">
        <Container size="xl" py={rem(28)}>
          <Flex
            justify={{
              base: "center",
              md: "space-between",
            }}
            align="center"
            wrap={"wrap"}
            gap="xs"
          >
            <Text c="white" size="xs">
              © Inbound Platform Corp. All rights reserved.
            </Text>
            <Image
              alt="Payment Methods"
              src={getAssetUrl("/payment-methods.webp")}
              height={30}
              width={220}
            />
          </Flex>
        </Container>
      </Box>
    </AppShell.Footer>
  );
}
